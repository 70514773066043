import { Link } from 'react-router-dom';

import { Logo } from '@icons/svg';

function MainLogo() {
  return (
    <Link to='/' className='block py-[1.4rem] px-[.8rem] h-[5.7rem] leading-[1.5]'>
      <Logo className='w-full' />
    </Link>
  );
}

export default MainLogo;
