import { toastify } from '@components/Toast';

type ErrorWithMessage = { message: string };

const isObject = (value: unknown): value is object => typeof value === 'object' && value !== null;

const isErrorWithMessage = (error: unknown): error is ErrorWithMessage =>
  isObject(error) &&
  'message' in error &&
  typeof (error as Record<string, unknown>).message === 'string';

const toErrorWithMessage = (maybeError: unknown): ErrorWithMessage => {
  if (isErrorWithMessage(maybeError)) return maybeError;

  try {
    return new Error(JSON.stringify(maybeError));
  } catch (error) {
    return new Error(String(maybeError));
  }
};

const getErrorMessage = (error: unknown) => toErrorWithMessage(error).message;

export const reportError = (error: unknown) => {
  const errorMessage = getErrorMessage(error);
  toastify(errorMessage, { type: 'error' });
  // 필요 시 error logging 추가
};
