import { jwtDecode } from '@teampetfriends/utils';

import axios, { AxiosError } from 'axios';

import { loadingControl } from '@components/Loading/utils';
import { authStore } from '@hooks/useSyncAuth';
import { SentryHelper } from '@modules/sentry';
import { ApiError } from '@modules/sentry/axiosError';
import { type TokenInfo, getTokenData } from '@utils/token';

import { reportError } from './error';
import {
  incorrectPermissionInterceptor,
  tokenRefreshInterceptor,
  tokenRefreshInterceptorWithDebounce,
} from './interceptor';
import { StatusCode } from './type';

const getBaseUrl = () => {
  const { storage: snapShot } = authStore.get(['accessToken']);
  const checkUser = getTokenData();

  if (snapShot) {
    const storageInfo = jwtDecode<TokenInfo>(snapShot);

    return !storageInfo?.is_vendor
      ? import.meta.env.VITE_BASE_ADMIN_URL
      : import.meta.env.VITE_BASE_VENDOR_URL;
  }

  return !checkUser?.is_vendor
    ? import.meta.env.VITE_BASE_ADMIN_URL
    : import.meta.env.VITE_BASE_VENDOR_URL;
};

const abortController = new AbortController();

export const axiosInstance = (isLoading = false) => {
  let loading = isLoading;
  const { close, open } = loadingControl();

  const api = axios.create({
    baseURL: getBaseUrl(),
    signal: abortController.signal,
  });

  if (import.meta.env.NODE_ENV !== 'test') {
    api.interceptors.request.use(
      async (config) => {
        if (config.url?.includes('/login')) return config;

        if (loading)
          open(
            config.method === 'get' ? '데이터를 불러오는 중입니다.' : '데이터를 처리하는 중입니다.'
          );

        const {
          cookie: [accessToken],
        } = authStore.get(['accessToken']);

        if (accessToken) {
          const decodeToken = jwtDecode<TokenInfo>(accessToken);
          const isExpired = decodeToken ? decodeToken.exp < Date.now() / 1000 : true;

          if (isExpired) {
            const tokens = await tokenRefreshInterceptor();
            if (tokens) config.headers.Authorization = `Bearer ${tokens.accessToken}`;
          } else {
            config.headers.Authorization = `Bearer ${accessToken}`;
          }
        }

        return config;
      },
      (error) => {
        loading = false;
        close();
        Promise.reject(error);
      }
    );

    api.interceptors.response.use(
      async (response) => {
        close();
        return response;
      },
      async (error) => {
        const { response } = error;

        close();

        if (response.status === StatusCode.Unauthorized) {
          tokenRefreshInterceptorWithDebounce(error.config);
          return Promise.reject(error);
        }

        if (response.status === StatusCode.Forbidden) {
          incorrectPermissionInterceptor();
          return Promise.reject(error);
        }

        const apiError = new ApiError(error as AxiosError);
        reportError(response.data.message);

        if (apiError) {
          SentryHelper.apiLogException(apiError, {
            level: 'error',
            method: apiError.config?.method ?? '',
            url: apiError.config?.url ?? '',
            status: apiError.response?.status ?? 0,
            tags: [
              { key: 'request-url', value: apiError.config?.url ?? '' },
              { key: 'request-method', value: apiError.config?.method ?? '' },
              { key: 'request-status', value: apiError.response?.status ?? 0 },
            ],
          });
        }
        return Promise.reject(error);
      }
    );
  }

  return api;
};

export const client = axiosInstance;
