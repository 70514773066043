import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import BackgroundLayout from '@auth/components/BackgroundLayout';
import { useAuthInfoAction } from '@hooks/useSyncAuth';
import { getTokenData } from '@utils/token';

import PasswordForm from './components/PasswordForm';

function FindPassword() {
  const navigate = useNavigate();
  const { remove } = useAuthInfoAction();

  const checkUser = getTokenData();

  useEffect(() => {
    if (!checkUser) {
      remove();

      navigate('/login', { replace: true });
    }
  }, []);

  return (
    <BackgroundLayout>
      <PasswordForm />
    </BackgroundLayout>
  );
}

export default FindPassword;
