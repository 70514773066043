import { Flex } from '@teampetfriends/react-ui';

import Button from '@components/Button';
import menualDownload from '@icons/svg/menual_download.png';

function MenualDownload() {
  return (
    <Flex
      css={{ position: 'absolute', bottom: 0, width: '100%', height: '8rem' }}
      align='center'
      justify='center'
    >
      <a href='/user_menual.pdf' download='판매자어드민_사용자_매뉴얼.pdf'>
        <Button color='white'>
          <Flex align='center' justify='center' css={{ columnGap: '.4rem' }}>
            <Flex align='center' justify='center'>
              <img
                src={menualDownload}
                alt='사용자 메뉴얼 다운로드'
                className='w-[2rem] h-[2rem]'
              />
            </Flex>
            사용자 메뉴얼 다운로드
          </Flex>
        </Button>
      </a>
    </Flex>
  );
}

export default MenualDownload;
