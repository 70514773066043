import { useMemo } from 'react';
import { Navigate } from 'react-router-dom';

import { getTokenData } from '@utils/token';

function MainRedirect() {
  const user = getTokenData();

  const url = useMemo(() => {
    const allowedType = user?.allowed_types;

    if (allowedType?.includes('VENDOR')) {
      return '/delivery/manage';
    }

    if (allowedType?.includes('CONSIGNMENT')) {
      return '/outbound/list';
    }

    if (allowedType?.includes('PURCHASE')) {
      return '/coupon/request';
    }

    return '/delivery/manage';
  }, []);

  return <Navigate to={url} />;
}

export default MainRedirect;
