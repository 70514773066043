import { Flex, styled } from '@teampetfriends/react-ui';

import { getTokenData } from '@utils/token';

import MainLogo from './components/MainLogo';
import Menu from './components/Menu';
import MenualDownload from './components/MenualDownload';

const AsideElement = styled(Flex, {
  position: 'fixed',
  top: 0,
  maxWidth: '25.1rem',
  background: 'var(--color-white)',
  width: '100%',
  borderRight: '.1rem solid var(--color-grey-300)',
  height: '100vh',
  transition: 'all .3s',
  zIndex: 10,
});

function Aside({ isOpen }: { isOpen: boolean }) {
  const checkUser = getTokenData();

  return (
    <AsideElement
      as='aside'
      direction='column'
      css={{
        left: isOpen ? 0 : -252,
      }}
    >
      <MainLogo />
      <Menu />
      {checkUser?.is_vendor && <MenualDownload />}
    </AsideElement>
  );
}

export default Aside;
