/*
role
PURCHASE  ("매입")
VENDOR  ("업체배송")
CONSIGNMENT  ("위탁배송")
*/

export const ADMIN_MENUS = [
  {
    name: '상품',
    ico: 'MenuProduct',
    visible: true,
    role: ['VENDOR', 'CONSIGNMENT', 'PURCHASE'],
    isNew: false,
    subMenu: [
      { name: '상품 조회', path: '/product/list', visible: true },
      { name: '상품 요청 관리', path: '/admin/request/list', visible: true },
    ],
  },
  {
    name: '업체배송',
    ico: 'MenuDelivery',
    visible: true,
    role: ['VENDOR', 'CONSIGNMENT', 'PURCHASE'],
    isNew: false,
    subMenu: [
      { name: '배송관리', path: '/delivery/manage', visible: true },
      { name: '정산 내역', path: '/vendor-settlement/list', visible: true },
    ],
  },
  {
    name: '판매자 심쿵배송',
    ico: 'MenuDelivery',
    visible: true,
    role: ['VENDOR', 'CONSIGNMENT', 'PURCHASE'],
    isNew: false,
    subMenu: [
      { name: '출고현황', path: '/outbound/list', visible: true },
      { name: '품목관리', path: '/items/list', visible: true },
      { name: '입고 요청/내역', path: '/inbound/request', visible: true },
      { name: '재고조회', path: '/stocks/list', visible: true },
      { name: '정산내역', path: '/settlement/list', visible: true },
    ],
  },
  {
    name: '교환/반품',
    ico: 'MenuAnnounce',
    visible: true,
    role: ['VENDOR', 'CONSIGNMENT', 'PURCHASE'],
    isNew: false,
    subMenu: [{ name: '교환/반품', path: '/exchange-return', visible: true }],
  },
  {
    name: '쿠폰',
    ico: 'MenuCoupon',
    visible: true,
    role: ['VENDOR', 'CONSIGNMENT', 'PURCHASE'],
    isNew: true,
    subMenu: [
      { name: '쿠폰 요청 관리', path: '/coupon/request', visible: true },
      { name: '쿠폰 리포트', path: '/coupon/report', visible: true },
      { name: '정산내역', path: '/coupon-settlement/list', visible: true },
    ],
  },
];

export const VENDOR_MENUS = [
  {
    name: '상품',
    ico: 'MenuProduct',
    visible: true,
    role: ['VENDOR', 'CONSIGNMENT'],
    isNew: false,
    subMenu: [
      { name: '상품등록', path: '/product/form', visible: true },
      { name: '상품 조회', path: '/product/list', visible: true },
      { name: '상품정보 수정 요청', path: '/vendor/product/modify', visible: true },
      { name: '판매 상품 연결', path: '/product/package', visible: true },
    ],
  },
  {
    name: '업체배송',
    ico: 'MenuDelivery',
    visible: true,
    role: ['VENDOR'],
    isNew: false,
    subMenu: [
      { name: '배송관리', path: '/delivery/manage', visible: true },
      { name: '정산 내역', path: '/vendor-settlement/list', visible: true },
    ],
  },
  {
    name: '판매자 심쿵배송',
    ico: 'MenuDelivery',
    visible: true,
    role: ['CONSIGNMENT'],
    isNew: false,
    subMenu: [
      { name: '출고현황', path: '/outbound/list', visible: true },
      { name: '품목관리', path: '/items/list', visible: true },
      { name: '입고 요청/내역', path: '/inbound/request', visible: true },
      { name: '재고조회', path: '/stocks/list', visible: true },
      { name: '정산내역', path: '/settlement/list', visible: true },
    ],
  },
  {
    name: '교환/반품',
    ico: 'MenuAnnounce',
    visible: true,
    role: ['VENDOR', 'CONSIGNMENT'],
    isNew: false,
    subMenu: [{ name: '교환/반품', path: '/exchange-return', visible: true }],
  },
  {
    name: '쿠폰',
    ico: 'MenuCoupon',
    visible: true,
    role: ['VENDOR', 'PURCHASE', 'CONSIGNMENT'],
    isNew: true,
    subMenu: [
      { name: '쿠폰 관리', path: '/coupon/request', visible: true },
      { name: '쿠폰 리포트', path: '/coupon/report', visible: true },
      { name: '정산내역', path: '/coupon-settlement/list', visible: true },
    ],
  },
  // {
  //   name: '스토어 관리',
  //   ico: 'MenuSetting',
  //   subMenu: [
  //     { name: '담당자 정보', path: '/manager/info' },
  //     { name: '배송 정보', path: '/manager/info' },
  //   ],
  // },
];
