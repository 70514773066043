import { captureException, captureMessage, SeverityLevel, User } from '@sentry/react';

import { DefaultError } from './defaultError';

type ApiLogOptions = {
  level: SeverityLevel;
  method: string;
  status: number;
  url: string;
} & DefaultLogOptions;

type DefaultLogOptions = {
  tags?: { key: string; value: string | number | null | undefined | boolean | symbol }[];
  snapshot?: {
    userInfo?: User;
    localStorage?: boolean;
  };
};

const words = ['user', 'cmcs', 'experiment', 'amplitude', 'growth'];

const getLocalStorageData = () => {
  if (!localStorage) return {};
  const acc: Record<string, unknown> = {};

  // eslint-disable-next-line guard-for-in
  for (const key in localStorage) {
    const regexPattern = new RegExp(words.join('|'));

    if (regexPattern.test(key)) {
      acc[key] = localStorage.getItem(key);
    }
  }

  return acc;
};

export class SentryHelper {
  static defaultLogException(exception: any, options?: DefaultLogOptions) {
    captureException(exception, (scope) => {
      if (!options) return scope;

      scope.setUser({
        ...(options.snapshot?.userInfo ?? {}),
      });

      if (options.tags?.length) {
        for (const { key, value } of options.tags) scope.setTag(key, value);
      }

      if (options.snapshot?.localStorage) {
        scope.setContext('localStorage', { ...getLocalStorageData() });
      }

      return scope;
    });
  }

  static customLogException(
    { name, message }: { name: string; message: string },
    options?: DefaultLogOptions
  ) {
    this.defaultLogException(new DefaultError({ name, message }), options);
  }

  static apiLogException(exception: any, options?: ApiLogOptions) {
    captureException(exception, (scope) => {
      if (!options) return scope;

      scope.setUser({
        email: 'user email',
        name: 'user nickname or name',
        ...(options.snapshot?.userInfo ?? {}),
      });

      if (options.tags?.length) {
        for (const { key, value } of options.tags) scope.setTag(key, value);
      }

      scope.setFingerprint([options.method, String(options.status), options.url]);
      scope.setLevel(options.level ?? 'error');

      if (options.snapshot?.localStorage) {
        scope.setContext('localStorage', { ...getLocalStorageData() });
      }

      return scope;
    });
  }

  static logMessage(...args: Parameters<typeof captureMessage>) {
    captureMessage(...args);
  }
}
