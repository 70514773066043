import { useId } from 'react';

import { Flex, Modal as M, ModalBase, Typo } from '@teampetfriends/react-ui';

import { useAtom } from 'jotai';

import { ReactComponent as Close } from '@icons/svg/close.svg';

import { modalAtom, ModalKey, ModalState } from './hooks/useModal';
import { DEFAULT_SIZE, MODAL_STYLE, NO_CLOSE_BUTTON_SIZES } from './constant';

function ModalHeader({
  title,
  size,
  id,
  close,
}: Pick<ModalState, 'title' | 'size' | 'id'> & { close: (id: ModalKey) => void }) {
  return (
    <Flex as={M.Title} align='center' justify='between' css={{ position: 'relative' }}>
      {title && (
        <Typo as='h4' css={{ fontWeight: 700, fontSize: '2rem' }}>
          {title}
        </Typo>
      )}
      {!NO_CLOSE_BUTTON_SIZES.includes(size || DEFAULT_SIZE) && (
        <button type='button' onClick={() => close(id)} className='absolute right-0 top-0'>
          <Close />
        </button>
      )}
    </Flex>
  );
}

function Modal() {
  const modalId = useId();
  const [modals, setModals] = useAtom(modalAtom);

  const overlayClick = (id: ModalKey) => {
    setModals((prev) => prev.map((it) => (it.id === id ? { ...it, open: false } : it)));
  };

  return (
    <div>
      {modals.map(({ id, content, open, title, size = DEFAULT_SIZE }) => {
        return (
          <ModalBase
            key={`${modalId}$-${id}`}
            open={open}
            overlayCss={{
              maxWidth: 'unset',
              width: '100vw',
              left: 0,
              top: 0,
            }}
            contentCss={{
              transform: 'translate(-50%, -50%)',
              width: 'fit-content',
              minHeight: 'fit-content',
              maxWidth: 'unset',
            }}
            onOpenChange={() => overlayClick(id)}
          >
            <div
              className={`flex flex-col rounded-[.4rem] bg-white shadow-md ${MODAL_STYLE[size]}`}
            >
              <ModalHeader title={title} size={size} id={id} close={overlayClick} />
              <Flex
                direction='column'
                css={
                  NO_CLOSE_BUTTON_SIZES.includes(size || DEFAULT_SIZE)
                    ? {}
                    : {
                        overflow: 'auto',
                        marginTop: '2.4rem',
                      }
                }
              >
                {content}
              </Flex>
            </div>
          </ModalBase>
        );
      })}
    </div>
  );
}

export default Modal;
