import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { Flex, styled, Typo } from '@teampetfriends/react-ui';
import { jwtDecode } from '@teampetfriends/utils';

import { useSetAtom } from 'jotai';

import { logout } from '@auth/Login/firebase/helper';
import Button from '@components/Button';
import { optionAtom } from '@hooks/useOptions/useOptions';
import { useAuthInfo } from '@hooks/useSyncAuth';
import menuImg from '@icons/svg/menu.png';
import { type TokenInfo, getTokenData } from '@utils/token';

const HeaderElement = styled(Flex, {
  position: 'fixed',
  background: '#fff',
  borderBottom: '.1rem solid #d1d6db',
  height: '5.7rem',
  padding: '0 1.4rem 0 4.2rem',
  top: 0,
  transition: 'all .3s',
  zIndex: 8,
});

function Header({ action, isOpen }: { action: () => void; isOpen: boolean }) {
  const userData = getTokenData();
  const navigate = useNavigate();
  const serResetOptions = useSetAtom(optionAtom);

  const { storage, remove } = useAuthInfo(['accessToken']);

  const userName = useMemo(() => {
    if (storage) {
      const userData = jwtDecode<TokenInfo>(storage);
      return userData?.user_name ?? '';
    }

    const user = getTokenData();

    return user?.user_name;
  }, [storage]);

  const onClickLogout = () => {
    if (!userData?.is_vendor) logout();
    remove();
    serResetOptions(null);
    navigate('/login', { replace: true });
  };

  return (
    <HeaderElement
      as='header'
      css={{
        width: `calc(100% - ${isOpen ? '25rem' : '0rem'})`,
        marginLeft: isOpen ? 250 : 0,
      }}
      align='center'
      justify='between'
    >
      <button type='button' className='w-[3.2rem] h-[4rem]' onClick={action}>
        <Flex align='center' justify='center'>
          <img src={menuImg} alt='메뉴' />
        </Flex>
      </button>

      <Flex align='center' justify='between' css={{ columnGap: '$16' }}>
        <Typo as='span' css={{ fontSize: '$16', lineHeight: '4rem' }}>
          {userName}
        </Typo>
        <Button onClick={onClickLogout}>로그아웃</Button>
      </Flex>
    </HeaderElement>
  );
}

export default Header;
