import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { ToastContainer } from 'react-toastify';

import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import App from './App';

import '@auth/Login/firebase/config';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.min.css';
import './index.css';

// TODO: 함보고 session replay
// sourcemap upload : <bash> npx @sentry/wizard@latest -i sourcemaps
Sentry.init({
  dsn: 'https://baa421fa8ba7f0e89b8119bd776a7aaf@o242719.ingest.sentry.io/4505990466437120',
  integrations: [
    new Sentry.Breadcrumbs({
      console: true,
    }),
    new Sentry.BrowserTracing({}),
  ],
  // Performance Monitoring
  tracesSampleRate: 0, // Capture 100% of the transactions, reduce in production!
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      retry: false,
    },
  },
});

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ToastContainer />
      <BrowserRouter>
        <App />
      </BrowserRouter>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </React.StrictMode>
);
