import { useCallback, useMemo } from 'react';

import { jwtDecode } from '@teampetfriends/utils';

import { useAtom } from 'jotai';

import { ADMIN_MENUS, VENDOR_MENUS } from '@components/Aside/constant';
import { useCategoryGroup } from '@hooks/useCategoryGroup';
import { useAuthInfo } from '@hooks/useSyncAuth';
import { visibleAtom } from '@store/atoms';
import { getTokenData, TokenInfo } from '@utils/token';

import Accordion from './Accordion';

function Menu() {
  const [visible] = useAtom(visibleAtom);
  const { storage } = useAuthInfo(['accessToken']);

  useCategoryGroup();

  const isArrayIncluded = (roles: string[], allowedType?: string[]): boolean => {
    return roles.some((element) => allowedType?.includes(element));
  };

  const checkVisibleMenuList = useCallback(
    (menuList: typeof ADMIN_MENUS) => {
      if (visible === 'invisible') return menuList.filter((item) => item.visible);
      return menuList;
    },
    [visible]
  );

  const menus = useMemo(() => {
    const user = storage ? jwtDecode<TokenInfo>(storage) : getTokenData();

    const constMenuList = user?.is_vendor ? VENDOR_MENUS : ADMIN_MENUS;
    const menuList = checkVisibleMenuList(constMenuList);
    const allowedType = user?.allowed_types;

    if (allowedType?.length === 3) return menuList;

    return menuList.filter(({ role }) => {
      return isArrayIncluded(role, allowedType);
    });
  }, [storage, visible]);

  return (
    <nav>
      <ul>
        {menus.map((item) => (
          <Accordion key={item.name} {...item} />
        ))}
      </ul>
    </nav>
  );
}
export default Menu;
