import { ChangeEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Flex, Typo } from '@teampetfriends/react-ui';

import { useSetAtom } from 'jotai';
import { useForm } from 'react-hook-form';

import { postVendorLogin } from '@auth/Login/apis';
import Button from '@components/Button';
import { Input } from '@components/Input';
import { toastify } from '@components/Toast';
import { useAuthInfoAction } from '@hooks/useSyncAuth';
import { userIdAtom } from '@store/atoms';
import { useMutation } from '@tanstack/react-query';
import { getTokenData } from '@utils/token';

import { ID_PASSWORD_REQUIRED_ERROR_MESSAGE, MD_EMAIL } from '../../constant';

function LoginForm() {
  const navigate = useNavigate();
  const { update } = useAuthInfoAction();

  const setUserId = useSetAtom(userIdAtom);
  const {
    register,
    getValues,
    formState: { errors },
  } = useForm({ defaultValues: { account: '', password: '' } });
  const [errorMessage, setErrorMessage] = useState('');

  const { mutate } = useMutation(postVendorLogin, {
    onSuccess: (res) => {
      try {
        const { accessToken, refreshToken } = res;

        update({
          accessToken,
          refreshToken,
        });

        const user = getTokenData();
        if (!user?.password_reset) {
          navigate('/login/password');
          return;
        }
        navigate('/', { replace: true });
      } catch (err) {
        toastify('로그인에 실패하였습니다, 네트워크를 확인해주세요.', {
          type: 'error',
        });
        console.error(err);
      }
    },
    onError: (err: { response: { data: { message: string } } }) => {
      toastify(err.response.data.message ?? '로그인에 실패하였습니다, 네트워크를 확인해주세요.', {
        type: 'error',
      });
    },
  });

  const onSubmitHandler = (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    const params = { ...getValues() };

    if (Object.values(params).includes('')) {
      setErrorMessage(ID_PASSWORD_REQUIRED_ERROR_MESSAGE);
      return false;
    }

    setErrorMessage('');
    mutate({ ...params, originDomain: 'SELLER' });
  };

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = e;
    setUserId(value);
    if (errorMessage) setErrorMessage('');
  };

  return (
    <div className='rounded-[1rem] bg-white p-[4rem] flex items-center flex-col w-full shadow-md'>
      <Typo
        as='h3'
        css={{
          fontSize: '2.4rem',
          fontWeight: 700,
          marginBottom: '.8rem',
        }}
      >
        판매자 로그인
      </Typo>
      <Typo
        as='p'
        css={{
          fontSize: '$14',
          marginBottom: '$16',
          textAlign: 'center',
          color: 'var(--color-black-400)',
          lineHeight: '2.1rem',
        }}
      >
        반려동물 1등 쇼핑몰에서 <br />
        50만명의 펫프렌즈 고객을 만날 수 있어요!
      </Typo>

      <form onSubmit={onSubmitHandler}>
        <Input
          placeholder='아이디를 입력해주세요.'
          className='w-full h-[6rem] border border-solid border-[#cfd4d9] rounded-[.4rem] mb-[1.4rem] pl-[2rem]'
          {...register('account', {
            onChange: onChangeHandler,
            required: ID_PASSWORD_REQUIRED_ERROR_MESSAGE,
          })}
        />
        <Input
          type='password'
          placeholder='비밀번호를 입력해주세요.'
          className='w-full h-[6rem] border border-solid border-[#cfd4d9] rounded-[.4rem] mb-[1rem] pl-[2rem]'
          {...register('password', {
            required: ID_PASSWORD_REQUIRED_ERROR_MESSAGE,
          })}
        />

        {(errorMessage || errors?.account?.message || errors?.password?.message) && (
          <Typo
            as='p'
            css={{
              width: '100%',
              textAlign: 'left',
              color: 'var(--color-red-100)',
              marginBottom: '1.1rem',
              fontSize: '$14',
            }}
          >
            {errorMessage || ID_PASSWORD_REQUIRED_ERROR_MESSAGE}
          </Typo>
        )}

        <Button type='submit' size='large' color='black' width='100%'>
          <Typo as='span' css={{ fontWeight: 700, fontSize: '1.8rem', color: 'inherit' }}>
            로그인
          </Typo>
        </Button>
      </form>

      <Typo
        as='span'
        css={{
          margin: '1.4rem 0 1.6rem',
          fontSize: '$13',
          color: '#666',
        }}
      >
        아이디/비밀번호를 잊으셨다면 담당 MD분께 연락 바랍니다.
      </Typo>

      <div className='w-full pt-[1.8rem] pl-[2rem] pb-[1.4rem] bg-grey-30'>
        <strong className='block text-[1.6rem] mb-[.4rem]'>입점문의를 도와드려요!</strong>
        <Flex align='center' css={{ columnGap: '1rem' }}>
          <Typo
            as='span'
            css={{ fontSize: '1.3rem', color: 'var(--color-black-400)', lineHeight: '2rem' }}
          >{`펫프렌즈 입점 문의 신청: ${MD_EMAIL}`}</Typo>
          <button
            type='button'
            className='h-[2.4rem] px-[.4rem] rounded-[.4rem] border border-blue-50 text-blue-50'
          >
            메일복사
          </button>
        </Flex>
      </div>
    </div>
  );
}

export default LoginForm;
