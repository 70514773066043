import { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';

export class ApiError<T = unknown> extends AxiosError {
  config?: InternalAxiosRequestConfig<T> | undefined;

  code?: string | undefined;

  request?: any;

  response?: AxiosResponse<T>;

  declare status?: number | undefined;

  toJSON: () => object;

  constructor(error: AxiosError<T>, message?: string) {
    super(message ?? error.message);
    const errorStatus = error.response?.status || 0;

    this.name = getErrorName(errorStatus);
    this.stack = error.stack;
    this.config = error.config;
    this.code = error.code;
    this.request = error.request;
    this.response = error.response;
    this.toJSON = error.toJSON;
  }
}

const getErrorName = (status: number) => {
  let name = 'Api Error';
  switch (status) {
    case 400:
      name = 'Bad Request';
      break;
    case 401:
      name = 'Unauthorized';
      break;
    case 403:
      name = 'Forbidden';
      break;
    case 404:
      name = 'Not Found';
      break;
    case 405:
      name = 'Method Not Allowed';
      break;
    case 500:
      name = 'Internal Server Error';
      break;
    case 503:
      name = 'Time Out';
      break;
    default:
      name = `[${status}] Api Error`;
  }

  return name;
};
