/* eslint-disable no-underscore-dangle */
import React from 'react';

export interface ResultResponse<T> {
  code: number;
  message: string;
  response: {
    data: T;
    requestTime: string;
    statusCode: number;
    code?: number;
    hint?: string;
    message?: string;
  };
}

export interface PostResponse<T> {
  data: T;
  requestTime: string;
  statusCode: number;
}

export interface ErrorResponse {
  code: number;
  hint: string;
  message: string;
}

export interface PageInfoType {
  currentPage: number;
  endPageNum: number;
  totalPages: number;
  totalRows: number;
}

export type WithLabelType<T> = T & { label: string; id?: string | undefined };

export type WithSelectItemType<T> = T & { select: boolean };

export type SetStateType<T> = React.Dispatch<React.SetStateAction<T>>;

export type ValueOf<T> = T[keyof T];

export class FormsData<T extends { [key: string]: any }> {
  private _form = new FormData();

  public get formData(): FormData {
    return this._form;
  }

  public append(key: keyof T, value: T[keyof T]) {
    this._form.append(String(key), value);
  }

  // 'application/octet-stream' not supported 에러 솔루션
  public blobAppend(key: keyof T, value: string) {
    this._form.append(String(key), new Blob([value], { type: 'application/json' }));
  }
}

export type OptionalObject<T extends string | number | symbol, U> = Partial<Record<T, U>>;

export enum StatusCode {
  Unauthorized = 401,
  Forbidden = 403,
}
